// import React from 'react'
// import { Header, SubSiteNavbar, Card, CardOverlay, CardTwitter, Link, ContentBackground, Footer, ShareModal, EmailModal } from '../../components/fao/FaoComponents'
import {
    Header,
    Footer,
    Breadcrumb,
    SubSiteNavbar,
    Link,
    ContentBackground,
    Card,
    CardOverlay,
    DynamicList,
    DynamicListRow,
    ShareModal,
    EmailModal
} from '../../components/fao/FaoComponents'
// import bannerImage from '../../assets/test-images/one-health-banner.jpg'
// import cardImage1 from '../../assets/test-images/card-test-2.jpeg'
// import cardImage2 from '../../assets/test-images/card-test-3.jpeg'
// import cardImage3 from '../../assets/test-images/card-test-4.jpeg'
import DsLayoutFullWidth from '../../layouts/DsLayoutFullWidth'
// import heroVideoPoster from '../../assets/test-images/poster.jpg'
// import heroVideo from '../../assets/videos/emergencies-banner.mp4'
// import publImage from '../../assets/test-images/publication-1.jpg'
import bannerImage from '../../assets/test-images/one-health-banner.jpg'
// import cardImage1 from '../../assets/test-images/card-test-2.jpeg'
import publImage from '../../assets/test-images/publication-1.jpg'

import identityTest from '../../assets/test-images/identity-test.svg'

const NewsDetailExampleView = () => {
    return (
        <DsLayoutFullWidth>
            <Header subsite/>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="content-icons">

                            <Breadcrumb/>

                            <div className="content-icons-right">
                                <a href="#!" type="button" className="d-inline-flex" data-bs-toggle="modal" data-bs-target="#emailModal">
                                    <i className="bi bi-person"></i>
                                    <span>Login</span>
                                </a>
                                <a href="#!" type="button" className="d-inline-flex" data-bs-toggle="modal" data-bs-target="#emailModal">
                                    <i className="bi bi-pencil-square"></i>
                                    <span>Register</span>
                                </a>
                                <a href="#!" type="button" className="d-inline-flex" data-bs-toggle="modal" data-bs-target="#emailModal">
                                    <i className="bi bi-envelope"></i>
                                    <span>Email</span>
                                </a>
                            </div>


                        </div>
                    </div>

                </div>
            </div>



            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="page-title">Title of the Project</h2>
                        <SubSiteNavbar/>
                    </div>

                </div>
            </div>


            <main>



                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <h1 className="primary-heading">Title of the page</h1>
                            <div className="hero-banner" style={{backgroundImage: `url('${bannerImage}')`}}>
                                <div className="hero-caption">
                                    <h5 className="title-link">
                                        <a href="!#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus
                                            eum delectus ratione alias</a>
                                    </h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus eum delectus
                                        ratione alias, at qui autem deleniti aut odio dolorum corrupti maxime dolorem,
                                        iure porro possimus deserunt praesentium eaque velit.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col col-lg-9">
                            <h5 className="sub-title">With the support of</h5>

                            <div className="row mb-4">
                                <div className="col col-lg-4">
                                    <img src={identityTest} alt="corp logo" style={{height: "70px"}}/>
                                </div>
                                <div className="col col-lg-4">
                                    <img src={identityTest} alt="corp logo" style={{height: "70px"}}/>
                                </div>
                                <div className="col col-lg-4">
                                    <img src={identityTest} alt="corp logo" style={{height: "70px"}}/>
                                </div>
                            </div>

                            <ContentBackground className="bg-primary-light">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque explicabo
                                    laboriosam sed quam ad. Aliquam, rem. Reiciendis, quidem asperiores! Nesciunt beatae
                                    non ea dolorum? Labore, deserunt obcaecati! Illum, quam libero.</p>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque explicabo
                                    laboriosam sed quam ad.</p>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque explicabo
                                    laboriosam sed quam ad. Aliquam, rem. Reiciendis, quidem asperiores! Nesciunt beatae
                                    non ea dolorum? Labore, deserunt obcaecati! Illum, quam libero.</p>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque explicabo
                                    laboriosam sed quam ad.</p>
                            </ContentBackground>

                            <div className="row">
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                                <div className="col col-lg-3">
                                    <CardOverlay nodata/>
                                </div>
                            </div>

                            <h5 className="sub-title">Videos</h5>

                            <div className="row">
                                <div className="col col-lg-6">
                                    <Card hasVideo hasDate hasTitle titleHasLink hasIntLink className="bg-gray-dark"/>
                                </div>
                                <div className="col col-lg-6">
                                    <Card hasVideo hasDate hasTitle titleHasLink hasIntLink className="bg-gray-dark"/>
                                </div>
                            </div>

                            <h5 className="sub-title">Key publications</h5>

                            <DynamicList className="dynamic-list-publication ">
                                <DynamicListRow publication image={publImage}/>
                                <DynamicListRow publication image={publImage}/>
                            </DynamicList>

                        </div>

                        <div className="col col-lg-3">
                            <h5 className="sub-title">Related links</h5>
                            <Link
                                links={['Related Link Example', 'Related Link', 'Related Link Example', 'Related Link']}
                                external/>

                            <h5 className="sub-title">External links</h5>
                            <Link
                                links={['External Link', 'External Link Example', 'External Link', 'External Link Example']}
                                external/>

                            <ContentBackground className="bg-primary-light">
                                <h5>Contact</h5><p><a href="mailto:contact@fao.org">contact@fao.org</a></p>
                            </ContentBackground>
                        </div>
                    </div>
                </div>
            </main>


            <Footer/>
            <ShareModal/>
            <EmailModal/>
        </DsLayoutFullWidth>
    );
}

export default NewsDetailExampleView;