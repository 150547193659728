import DsLayout from '../../layouts/DsLayout'
import { Heading, RenderCode } from '../../components/design-system/DsComponents'


const TypographyView = () => {
    return (
        <DsLayout>
            <Heading size="h1">Typography</Heading>
            <p>Always use the FAO.org fonts.</p>

            <Heading size="h3">Font</Heading>
            <p>If your service is on fao.org subdomain you must use the Open Sans font.</p>
            <p>You should use an alternative typeface like Helvetica or Arial for services that are publicly available on different domains.</p>

            <Heading size="h3">Headings</Heading>
            <p>Mark up headings semantically using the appropriate &lt;h#&gt; level HTML element and use a heading class to apply Bootstrap styling. Style headings consistently to create a clear visual hierarchy throughout your service.</p>
            <p>Write all headings in sentence case (except the Title category).</p>

            <RenderCode withBorders={true}>
                <h2 className="page-title">Site title</h2>
            </RenderCode>
            <br/>
            <RenderCode withBorders={true}>
                <h1 className="primary-heading">Page title</h1>
            </RenderCode>
            <br/>
            <br/>
            <RenderCode withBorders={true}>
                <h4 className="title-highlight">Title</h4>
            </RenderCode>
            <br/>

            <RenderCode withBorders={true}>
                <h5 className="sub-title">Subtitle / Section</h5>
            </RenderCode>
            <br/>
            <RenderCode withBorders={true}>
                <h5 className="sub-title small">Subtitle small</h5>    
            </RenderCode>
            <br/>
            <RenderCode withBorders={true}>
                <h5 className="title-link"><a href="www.fao.org">Title Link</a></h5>
            </RenderCode>
            <br/>
            <RenderCode withBorders={true}>
                <h6 className="title-category">Category</h6>
            </RenderCode>
            <br/>
            <RenderCode withBorders={true}>
                <h6 className="date">26/11/2020 Rome time</h6>
            </RenderCode>
    
            <Heading size="h3">Headings Showcase</Heading>

            <RenderCode withBorders={true}>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                <h6>Heading 6</h6>
            </RenderCode>
                
            <Heading size="h3">Paragraphs</Heading>
            <Heading size="h5">Body</Heading>
            <p>The default paragraph font size is inherited from Bootstrap (no need for class).</p>

            <RenderCode withBorders={true}>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquid aperiam, aspernatur autem consequatur ea et illum impedit ipsum libero non placeat quaerat quas quisquam rem soluta veritatis. A, iure?</p>
            </RenderCode>

            <Heading size="h5">Blockquote</Heading>
            <p>A blockquote can be inserted between two paragraphs. This will create a floating box of text. If you set a bold text at the end of the quote, it will be treated as an <b>author</b></p>

            <RenderCode withBorders={true}>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis sollicitudin imperdiet. Cras id porttitor nisl, vitae interdum metus. Cras sagittis orci consequat, lobortis felis id, vehicula justo.</p>
                <blockquote>Cras sagittis orci consequat, lobortis felis id, vehicula justo. Nulla suscipit magna sed aliquet tincidunt. <b>Cicero</b></blockquote>
                <p>Maecenas pretium libero dolor. Suspendisse fringilla odio nulla, tincidunt euismod eros sodales gravida. Sed vitae tincidunt lectus. Nulla suscipit magna sed aliquet tincidunt. Vestibulum eget ex vehicula, tempus nunc ac, ultrices arcu. Quisque non eros at quam dictum consectetur. Vestibulum elementum arcu sit amet consectetur pellentesque. Aliquam faucibus felis elit, ut euismod dui sodales a. </p>
                <p>Duis vitae ligula quis ipsum scelerisque posuere. Morbi mollis, augue at pharetra facilisis, sapien risus elementum ligula, in tristique ante dolor vel ipsum. Vestibulum faucibus, turpis quis tincidunt consequat, ligula purus fringilla dolor, ac tempus risus tellus sit amet tellus. Vestibulum at tortor vel est condimentum ornare ut vitae ante. Vestibulum maximus tristique tempor. Cras accumsan diam nibh, vulputate facilisis dolor malesuada vel. </p>
            </RenderCode>
           
            <Heading size="h5">Photo caption, notes</Heading>
            <p>Used mainly in news details and details pages.</p>

            <RenderCode withBorders={true}>
                <p className="small text-color-gray-medium">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquid aperiam, aspernatur autem consequatur.</p>
            </RenderCode>
    
            <Heading size="h5">Highlighted text</Heading>
            <p>It is possible to make a portion of the text "highlighted" by adding the tag "mark".</p>

            <RenderCode withBorders={true}>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. <mark>A aliquid aperiam, aspernatur autem consequatur ea et illum impedit ipsum libero</mark> non placeat quaerat quas quisquam rem soluta veritatis. A, iure?</p>
            </RenderCode>
       
        </DsLayout>
    );
}

export default TypographyView;
